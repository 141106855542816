
// include the HUGE amplify theme for the login component
@import "../node_modules/@aws-amplify/ui-angular/theme.css";

// re-add this line to check whether we've included enough below
//@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// This doesn't seem necessary but may at some point
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

/* For customization we've combined ideas from bootstrap documentation:
https://getbootstrap.com/docs/5.3/customize/sass/
https://getbootstrap.com/docs/5.3/customize/optimize/

The imports depend on angular.json including the path ./node_modules/bootstrap/scss in the sytlesPreprocessorOptions
*/


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "functions";

// 2. Include any default variable overrides here
$primary: #033c89;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "variables";
@import "variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "maps";
@import "mixins";
@import "root";

// 6. Optionally include any other parts as needed
@import "utilities";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";

// 6a. Include bootstrap components as needed
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
// @import "accordion";
// @import "breadcrumb";
// @import "pagination";
@import "badge";
@import "alert";
// @import "progress";
@import "list-group";
@import "close";
// @import "toasts";
@import "modal";
@import "tooltip";
// @import "popover";
// @import "carousel";
@import "spinners";
// @import "offcanvas";
// @import "placeholders";

// 7a. This needed to be after components for some reason
@import "helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "utilities/api";

// 8. Add additional custom code here




